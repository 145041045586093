import React, { forwardRef, useImperativeHandle } from "react";
import "@metamask/legacy-web3";
import { InlineShareButtons } from "sharethis-reactjs";
import config from "../../lib/config";

import { toast } from "react-toastify";
toast.configure();
var Front_URL = config.Front_URL;

export const ShareNowRef = forwardRef((props, ref) => {
  const [ShareUrl, setShareUrl] = React.useState("");
  const [Show, setShow] = React.useState("");
  async function getShareoption(item) {
    if (item && item._id) {
      var currAddr = window.web3.eth.defaultAccount;
      var url = Front_URL + "/info/" + item.tokenCounts;
    } else {
      var url = Front_URL + "/user/" + item.curraddress;
    }
    setShareUrl(url);
    setShow(true);
    window.$("#social").modal("show");
  }
  useImperativeHandle(ref, () => ({
    async ShareSocial_Click(item) {
      getShareoption(item);
    },
  }));

  return (
    <div>
      <div class="modal report primary_modal" id="social">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Share link</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="social_icons_section">
                <InlineShareButtons
                  config={{
                    alignment: "center",
                    color: "social",
                    enabled: true,
                    font_size: 16,
                    language: "en",
                    networks: ["facebook", "twitter"],
                    padding: 12,
                    radius: 4,
                    show_total: false,
                    size: 40,
                    url: ShareUrl,
                    image: "",
                    description: "pyramidions NFT",
                    title: "pyramidions NFT",
                    message:
                      "Hi visit pyramidions NFT and buy Article what You want",
                    subject:
                      "Hi visit pyramidions NFT and buy Article what You want",
                    username: "pyramidions NFT",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="modal fade primary_modal" id="share_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="share_modalLabel">Share link</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                               
                                <InlineShareButtons
                                config={{
                                    alignment: 'center',
                                    color: 'social',
                                    enabled: true,
                                    font_size: 16,
                                    language: 'en',
                                    networks: [
                                    'telegram',
                                    'facebook',
                                    'twitter',
                                    ],
                                    padding: 12,
                                    radius: 4,
                                    show_total: false,
                                    size: 40,
                                    url: ShareUrl,
                                    image: 'https://bit.ly/2CMhCMC',
                                    description: 'Spidey Barder NFT',
                                    title: 'Spidey Barder NFT',
                                    message: 'Hi visit Spidey Barder NFT and buy Article what You want',
                                    subject: 'Hi visit Spidey Barder NFT and buy Article what You want',
                                    username: 'Spidey Barder NFT' 
                                }}
                                />
                                
                            
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
});
