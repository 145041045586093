import React, { useEffect } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import OwlCarousel from 'react-owl-carousel';
import $ from "jquery"
import config from "../lib/config";
import isEmpty from '../lib/isEmpty';

import {
  getallcollections
} from "../actions/v1/token";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Browse() {

const [collectionlist, Set_collectionlist] = React.useState(0);
const [page, Set_page] = React.useState(0);
const [showloadmore,setshowloadmore] = React.useState(true);

 useEffect(() => {  
  getcollection();
  },[]);

 async function getcollection(){
    var data = {
        "page" : page,
        limit  : 12
    }
    var lists = await getallcollections(data);
    console.log(lists,"================collectionlost")
    if(lists  && lists.data &&  lists.data.RespData){
         Set_collectionlist(lists.data.RespData)
         if(lists.data.RespData.length<12){
            setshowloadmore(false)
         }
    }
 }

 async function loadmore(){
    Set_page(parseInt(page)+parseInt(1))
    var data = {
        "page" : parseInt(page)+parseInt(1),
        limit  : 12
    }
    var lists = await getallcollections(data);
    console.log(lists,"================collectionlost")
    if(lists  && lists.data &&  lists.data.RespData && lists.data.RespData.length>0){
         Set_collectionlist(collectionlist.concat(lists.data.RespData))
         if(lists.data.RespData.length<12){
            setshowloadmore(false)
         }
    }else{
        setshowloadmore(false)
    }
 }

  function loadScript() {
         dropdown('#item_category');
         dropdown('#buy_category');
         dropdown('#items_type');
    function dropdown(e){
        var obj = $(e+'.dropdown');
        var btn = obj.find('.btn-selector');
        var dd = obj.find('ul');
        var opt = dd.find('li');
        
            obj.on("click", function() {
                dd.show();
            }).on("mouseleave", function() {
                dd.hide();
            })
            
            opt.on("click", function() {
                dd.hide();
                var txt = $(this).text();
                opt.removeClass("active");
                $(this).addClass("active");
                btn.text(txt);
            });
    }

  }

    var settings = {
        center: false,
        items:7,
        loop:false,
        margin:0,
        nav:true,
        navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
        dots:false,
        responsive:{
            1000:{
                items:7
            },
            600:{
                items:1
            },
            0:{
                items:1
            },
        }
      };
  return (
<div id="wrapper">
	 <ScrollToTopOnMount />
     <Header />
        <div className="no-bottom no-top" id="content">
           
             <section id="subheader" class="text-light">
                    <div class="center-y relative text-center">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <h1>Browse collections</h1>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>
            
            <section id="section-collections" className="browseSection pt30 pb30">
                <div className="container">
                <div class="col-lg-12">
                <div className="itemFilterTop">
                <div class="items_filter">
                </div>
                 <div className="itemCount">
                    <p>{collectionlist && collectionlist.length>0?collectionlist.length:0} Items</p> 
                </div>
                </div>
                </div>    
                <div className="de_tab tab_simple">
                 <div className="tab-content de_tab_content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-Trending" role="tabpanel" aria-labelledby="nav-Trending-tab">
                      <div className="row">
                                   {collectionlist && collectionlist.length>0 && collectionlist.map((item)=>{
                                    console.log(item,"======itemfrombrows")
                                    return (
                                         <div className="col-lg-3">
                                            <div className="nft_coll style-2">
                                                <div className="nft_wrap">
                                                    <a href={config.Front_URL+"/collections/"+item.conAddr}><img src={config.Back_URL+"collections/"+item.imageUser} className="lazy img-fluid" alt="" /></a>
                                                </div>
                                                <div className="nft_coll_pp">
                                                    {(!isEmpty(item.userinfo) && item.userinfo.curraddress && item.userinfo.image && item.userinfo.image!="")
                                                        ?
                                                        <a href={`${config.Front_URL}/user/${item.userinfo.curraddress}`}><img className="lazy" src={`${config.Back_URL}profile/${item.userinfo.image}`} alt="" /></a>
                                                      :                                                    
                                                        <a href={`${config.Front_URL}/user/${item.owneraddr}`}><img className="lazy" src={`${config.Back_URL}images/previewThumb.png`} alt="" /></a>
                                                     }
                                                     <i className="fa fa-check"></i>
 
                                                </div>
                                                <div className="nft_coll_info">
                                                    <a href={config.Front_URL+"/collections/"+item.conAddr}><h4>{item && item.symbol}</h4></a>
                                                    <span>{item && item.type && "BEP"+item.type}</span>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                   })}
                                   
                                </div>
                                {showloadmore &&
                                 <div className="text-center"><button type="button" class="btn-main" onClick={()=>loadmore()}>Load More</button></div>
                                 }  
                                    </div>
                                    
                                    <div className="tab-pane fade" id="nav-Top" role="tabpanel" aria-labelledby="nav-Top-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button type="button" class="btn-main">Load More</button></div>
                                    </div>
                                     <div className="tab-pane fade" id="nav-Art" role="tabpanel" aria-labelledby="nav-Art-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button  type="button" class="btn-main">Load More</button></div>
                                    </div>
                                     <div className="tab-pane fade" id="nav-Music" role="tabpanel" aria-labelledby="nav-Music-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button type="button" class="btn-main">Load More</button></div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Meme" role="tabpanel" aria-labelledby="nav-Meme-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button class="btn-main">Load More</button></div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-virtualWorld" role="tabpanel" aria-labelledby="nav-virtualWorld-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button class="btn-main">Load More</button></div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Collectibles" role="tabpanel" aria-labelledby="nav-Collectibles-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button class="btn-main">Load More</button></div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Sports" role="tabpanel" aria-labelledby="nav-Sports-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button class="btn-main">Load More</button></div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Cards" role="tabpanel" aria-labelledby="nav-Cards-tab">
                                       <div className="row">
                               
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-2.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Patternlicious</h4></a>
                                                <span>BEP20-61</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-3.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Skecthify</h4></a>
                                                <span>BEP20-126</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-5.jpg")}className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Virtuland</h4></a>
                                                <span>BEP20-85</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-6.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Papercut</h4></a>
                                                <span>BEP20-42</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-4.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Cartoonism</h4></a>
                                                <span>BEP20-73</span>
                                            </div>
                                        </div>
                                    </div>  
                                   <div className="col-lg-3">
                                        <div className="nft_coll style-2">
                                            <div className="nft_wrap">
                                                <a href="03_grey-collection.html"><img src={require("../assets/images/collections/coll-1.jpg")} className="lazy img-fluid" alt="" /></a>
                                            </div>
                                            <div className="nft_coll_pp">
                                                <a href="03_grey-collection.html"><img className="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" /></a>
                                                <i className="fa fa-check"></i>
                                            </div>
                                            <div className="nft_coll_info">
                                                <a href="03_grey-collection.html"><h4>Abstraction</h4></a>
                                                <span>BEP20-192</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="text-center"><button class="btn-main">Load More</button></div>
                                    </div>
                                </div>
                                
                            </div>

                            

                                <div className="spacer-double"></div>

                               
                        </div>
            </section>

            
            <Footer />   


        </div>  
    </div>

  );
}
