import React, { useRef, useEffect, useState } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Countdown, { zeroPad } from "react-countdown";
import $ from "jquery";
import TokenItem from "./separate/TokenItem";
import { LikeRef } from "./separate/LikeRef";
import { PurchaseNowRef } from "./separate/PurchaseNowRef";
import { PlaceAndAcceptBidRef } from "./separate/PlaceAndAcceptBidRef";
import { TransferRef } from "./separate/TransferRef";
import { PutOnSaleRef } from "./separate/PutOnSaleRef";
import { ReportNowRef } from "./separate/ReportNowRef";
import { ShareNowRef } from "./separate/ShareNowRef";
import { BurnRef } from "./separate/BurnRef";
import { CancelOrderRef } from "./separate/CancelOrderRef";
import ConnectWallet from "./separate/Connect-Wallet";
import config from "../lib/config";
import Single_ABI from "../ABI/BEP721.json";
import Multiple_ABI from "../ABI/BEP1155.json";
import Searchref from "./separate/Search";
import {
  CollectiblesList_Home,
  TokenCounts_Get_Detail_Action,
  getHotCollections,
  getbannercollection,
} from "../actions/v1/token";
import { getCurAddr } from "../actions/v1/user";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Exclusive() {
  const [CatBasedTokenList, setCatBasedTokenList] = useState({
    loader: false,
    All: { page: 1, list: [], onmore: true },
  });
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState("All");
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState("");
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState("");
  const [item, Set_item] = useState({});
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(0);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] =
    React.useState("init");
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] =
    React.useState("init");
  const [totaluserbidAmt, setTotaluserbidAmt] = React.useState(0);
  const [HitItem, Set_HitItem] = useState({});
  const [collections, set_collections] = React.useState("");
  const [bannerlist, setbannerlist] = React.useState([]);
  var searchtxt = getParameterByName("search");
  var categorytxt = getParameterByName("category");
  const [namesearch, setnamesearch] = useState("");
  const [categorysearch, setcategorysearch] = useState("all");
  const [typesearch, settypesearch] = useState("");
  const [startprice, setstartprice] = useState(0);
  const [endprice, setendprice] = useState(0);
  const [keysearch, setkeysearch] = useState(0);
  const [categoryid, setcategoryid] = useState("");

  var LikeForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const PurchaseNowForwardRef = useRef();
  const PutOnSaleForwardRef = useRef();
  const CancelOrderForwardRef = useRef();
  const BurnForwardRef = useRef();
  var ShareForwardRef = useRef();
  var ReportForwardRef = useRef();
  var TransferForwardRef = useRef();

  useEffect(() => {
    loadScript();
    getInit();
  }, []);

  async function getInit() {
    if (
      searchtxt &&
      searchtxt != "" &&
      searchtxt != null &&
      searchtxt != undefined
    ) {
      setnamesearch(searchtxt);
      searchstart({ name: searchtxt });
    } else if (
      categorytxt &&
      categorytxt != "" &&
      categorytxt != null &&
      categorytxt != undefined
    ) {
      setcategoryid(categorytxt);
      searchstart({ cat: categorytxt });
    } else {
      TokenListCall();
    }
  }

  async function searchstart(data = {}) {
    var currAddr = await getCurAddr();
    var name = categorysearch;
    var payload = {
      limit: 1000,
      page: 1,
      currAddr: currAddr,
      CatName:
        data &&
        data.cat &&
        data.cat != null &&
        data.cat != undefined &&
        data.cat != ""
          ? data.cat
          : categoryid,
      namesearch:
        data &&
        data.name &&
        data.name != null &&
        data.name != undefined &&
        data.name != ""
          ? data.name
          : namesearch,
      typesearch: typesearch,
      startprice: startprice,
      endprice: endprice,
      keysearch: keysearch,
      aucttype: "no",
      from: "Home",
    };
    var resp = await CollectiblesList_Home(payload);
    if (
      resp &&
      resp.data &&
      resp.data.from == "token-collectibles-list-home" &&
      resp.data.list.length > 0
    ) {
      setTokenList(resp.data.list);
    } else {
      setTokenList(resp.data.list);
    }
  }

  async function AfterWalletConnected() {
    // GetCategoryCall();
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function loadScript() {
    dropdown("#buy_category");
    dropdown("#items_type");
    dropdown("#item_category");

    function dropdown(e) {
      var obj = $(e + ".dropdown");
      var btn = obj.find(".btn-selector");
      var dd = obj.find("ul");
      var opt = dd.find("li");

      obj
        .on("mouseenter", function () {
          dd.show();
        })
        .on("mouseleave", function () {
          dd.hide();
        });

      opt.on("click", function () {
        dd.hide();
        var txt = $(this).text();
        opt.removeClass("active");
        $(this).addClass("active");
        btn.text(txt);
      });
    }
  }

  async function TokenListCall(data = {}) {
    var currAddr = await getCurAddr();
    var name = CatName;
    if (data.CatName) {
      name = data.CatName;
    }
    var payload = {
      limit: 1000,
      page:
        CatBasedTokenList[name] && CatBasedTokenList[name].page
          ? CatBasedTokenList[name].page
          : 1,
      currAddr: currAddr,
      CatName: name,
      from: "Home",
    };
    CatBasedTokenList.loader = true;
    console.log(
      CatBasedTokenList,
      "==================================CatBasedTokenList"
    );
    setCatBasedTokenList(CatBasedTokenList);
    var resp = await CollectiblesList_Home(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    console.log("home resp----", resp);
    if (
      resp &&
      resp.data &&
      resp.data.from == "token-collectibles-list-home" &&
      resp.data.list.length > 0
    ) {
      setTokenList(resp.data.list);
      if (typeof CatBasedTokenList[name] == "undefined") {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = resp.data.list;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    } else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  // Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">d</span>
        </span>
        <span className="timer_dots"> </span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">h</span>
        </span>
        <span className="timer_dots"> </span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">m</span>
        </span>
        <span className="timer_dots"> </span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">s</span>
        </span>
      </div>
    );
  };
  return (
    <div id="wrapper">
      <ScrollToTopOnMount />
      <Header />
      <div className="no-bottom no-top" id="content">
        <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          AddressUserDetails={AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          AfterWalletConnected={AfterWalletConnected}
        />
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        />
        <ShareNowRef ref={ShareForwardRef} />
        <ReportNowRef
          UserAccountAddr={UserAccountAddr}
          ref={ReportForwardRef}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          Accounts={Accounts}
          // GetUserBal={GetUserBal}
          AllowedQuantity={AllowedQuantity}
          Set_AllowedQuantity={Set_AllowedQuantity}
        />
        <BurnRef
          ref={BurnForwardRef}
          // GetUserBal={GetUserBal}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          TokenBalance={TokenBalance}
          Accounts={Accounts}
          item={item}
          Set_item={Set_item}
        />
        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          Accounts={Accounts}
          // GetUserBal={GetUserBal}
          Set_NoOfToken={Set_NoOfToken}
        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          Set_WalletConnected={Set_WalletConnected}
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
          WalletConnected={WalletConnected}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          AddressUserDetails={AddressUserDetails}
          Accounts={Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          totaluserbidAmt={totaluserbidAmt}
          setTotaluserbidAmt={setTotaluserbidAmt}
        />
        <BurnRef
          ref={BurnForwardRef}
          // GetUserBal={GetUserBal}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          TokenBalance={TokenBalance}
          Accounts={Accounts}
          item={item}
          Set_item={Set_item}
        />
        <TransferRef
          ref={TransferForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          TokenBalance={TokenBalance}
          Accounts={Accounts}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          UserAccountAddr={UserAccountAddr}
          UserAccountBal={UserAccountBal}
          TokenBalance={TokenBalance}
          Accounts={Accounts}
          // GetUserBal={GetUserBal}
        />
        <section id="subheader" class="text-light">
          <div class="center-y relative text-center">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1>Browse items</h1>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </section>

        <section aria-label="section" className="pt30 pb30">
          <div class="container">
            <div class="row wow fadeIn">
              <Searchref
                setTokenList={setTokenList}
                TokenList={TokenList}
                CatName={CatName}
                setCatName={setCatName}
                aucttype="no"
                setkeysearch={setkeysearch}
                keysearch={keysearch}
                categoryid={categoryid}
                setcategoryid={setcategoryid}
                categorysearch={categorysearch}
                setcategorysearch={setcategorysearch}
                startprice={startprice}
                setstartprice={setstartprice}
                endprice={endprice}
                setendprice={setendprice}
                typesearch={typesearch}
                settypesearch={settypesearch}
                namesearch={namesearch}
                setnamesearch={setnamesearch}
              />
              {TokenList && TokenList.length > 0
                ? TokenList.map((item) => {
                    return (
                      <TokenItem
                        id={item._id}
                        item={item}
                        Set_item={Set_item}
                        LikedTokenList={LikedTokenList}
                        hitLike={LikeForwardRef.current.hitLike}
                        UserAccountAddr={UserAccountAddr}
                        UserAccountBal={UserAccountBal}
                        PutOnSale_Click={
                          PutOnSaleForwardRef.current.PutOnSale_Click
                        }
                        PurchaseNow_Click={
                          PurchaseNowForwardRef.current.PurchaseNow_Click
                        }
                        PlaceABid_Click={
                          PlaceABidForwardRef.current.PlaceABid_Click
                        }
                        Set_Bids={Set_Bids}
                        Bids={Bids}
                        Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                        Set_MyTokenBalance={Set_MyTokenBalance}
                        Set_MyTokenDetail={Set_MyTokenDetail}
                        Set_TokenBalance={Set_TokenBalance}
                        Set_AllowedQuantity={Set_AllowedQuantity}
                        Set_YouWillPay={Set_YouWillPay}
                        Set_YouWillPayFee={Set_YouWillPayFee}
                        Set_YouWillGet={Set_YouWillGet}
                        // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                        Burn_Click={BurnForwardRef.current.Burn_Click}
                        Transfer_Click={
                          TransferForwardRef.current.Transfer_Click
                        }
                        CancelOrder_Click={
                          CancelOrderForwardRef.current.CancelOrder_Click
                        }
                        WalletConnected={WalletConnected}
                        //Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                        SubmitReport_Click={
                          ReportForwardRef.current.SubmitReport_Click
                        }
                        ShareSocial_Click={
                          ShareForwardRef.current.ShareSocial_Click
                        }
                        aucttype="no"
                        from={0}
                      />
                    );
                  })
                : "No record found"}

              {/*
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="de_countdown"><Countdown date={`${year}-02-28T12:00:00`} renderer={renderer} /></div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-1.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Pinky Ocean</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.08 BNB<span>1/20</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>50</span>
                                    </div>                            
                                </div>
                                </div> 
                            </div>
                        </div>                 
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-10.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-2.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Deep Sea Phantasy</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.06 BNB<span>1/22</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>80</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="de_countdown"><Countdown date={`${year}-02-28T12:00:00`} renderer={renderer} /></div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-11.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-3.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Rainbow Style</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.05 BNB<span>1/11</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>97</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                            <div className="de_countdown">APY 8651.75%</div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-12.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-4.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Two Tigers</h4>
                                    </a>
                                    <div class="nft__item_price">
                                       Stakers<span>125</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Stake Now</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>73</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                            <div className="de_countdown">APY 8651.75%</div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-9.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-4.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>The Truth</h4>
                                    </a>
                                    <div class="nft__item_price">
                                    Stakers<span>6925</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                    <a href="#">Stake Now</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>26</span>
                                    </div>                                 
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="de_countdown"><Countdown date={`${year}-02-28T12:00:00`} renderer={renderer} /></div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-2.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-2.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Running Puppets</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.03 BNB<span>1/24</span>
                                    </div>    
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>45</span>
                                    </div>                                  
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-3.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-1.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>USA Wordmation</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.09 BNB<span>1/25</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>76</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="de_countdown"><Countdown date={`${year}-02-28T12:00:00`} renderer={renderer} /></div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-5.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Loop Donut</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.09 BNB<span>1/14</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>94</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="de_countdown"><Countdown date={`${year}-02-28T12:00:00`} renderer={renderer} /></div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-5.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-3.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Lady Copter</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.08 BNB<span>1/21</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>75</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-7.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-5.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Purple Planet</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.02 BNB<span>1/18</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>93</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="de_countdown"><Countdown date={`${year}-02-28T12:00:00`} renderer={renderer} /></div>
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-6.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-6.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Oh Yeah!</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.025 BNB<span>1/12</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>6</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-8.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-7.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>This is Our Story</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.035 BNB<span>1/8</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>21</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-9.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-6.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Pixel World</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.015 BNB<span>1/25</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>46</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-12.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/anim-8.webp")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>I Believe I Can Fly</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.055 BNB<span>1/4</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>54</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-4.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-7.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Cute Astronout</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.005 BNB<span>1/30</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>32</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                       
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="nft__item">
                                <div class="author_list_pp">
                                    <a href="dark-author.html">                                    
                                        <img class="lazy" src={require("../assets/images/author/author-1.jpg")} alt="" />
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="nft__item_wrap">
                                    <a href="dark-item-details.html">
                                        <img src={require("../assets/images/items/static-8.jpg")} class="lazy nft__item_preview" alt="" />
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a href="dark-item-details.html">
                                        <h4>Teal Ocean</h4>
                                    </a>
                                    <div class="nft__item_price">
                                        0.025 BNB<span>1/12</span>
                                    </div>
                                    <div className="nft-item-group">
                                    <div class="nft__item_action">
                                        <a href="#">Place a bid</a>
                                    </div>
                                    <div class="nft__item_like">
                                        <i class="fa fa-heart"></i><span>24</span>
                                    </div>                                 
                                </div>
                                </div> 
                            </div>
                        </div>
                    */}

              {/* <div class="col-md-12 text-center">
                            <a href="#" id="loadmore" class="btn-main wow fadeInUp lead">Load more</a>
                        </div>    */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
