let EnvName = "demo";
let Front_URL = "";
let Back_URL = "";
let v1Url = "";
let limit = "12";
let limitMax = "3000";
let fee = 2500000000000000000;
let decimalvalues = 1000000000000000000;
let toFixed = 6;
let IPFS_IMG = "https://ipfs.io/ipfs";
const singleType = 721;
const multipleType = 1155;
var providercon = null;
let networkVersion = "5";
// let networkVersion = "11155111";
let currencySymbol = "ETH";
let tokenSymbol = "WETH";
let owntokenSymbol = "WA";

// Demo;
const adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
const singleContract = "0x7514cA232748398761045348b8D62a9754E369AC";
const multipleContract = "0x0014C537130241DD2E7B9e8058812244a357c918";
const tokenAddress = "0x36ed7A4fd0101FB9F16a94070744FFc8b4e92364";
const esctokenAddr = "0x1e61900d66a3aD1C4f9fE01B4112F515351A67FB";
const defaultCollectionSingle = "0x702a1deB3A4Ef41B7FD2dDbB5d8be42E3Af6905f";
const defaultCollectionMultiple = "0x0BaC7F513f782Ea396Ab1e3e92094aFe8b691e47";


// const adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
// const singleContract = "0x57F461A3d39B01b331DE251d9995ed4fB496a435";
// const multipleContract = "0xF735C301399f5b7450Db3a6e894BC6217dAB8b2D";
// const tokenAddress = "0xea9a6a980d752782ab070473f846204f4054c8d0";
// const esctokenAddr = "0xc1fb308e43c2787434ac6e6332ec2f9f39b0f595";
// const defaultCollectionSingle = "0x54b2da81f9644dc2d017e89955152fece77af0bf";
// const defaultCollectionMultiple = "0xaa61aa6821d7a2337f5660db6dff9e23de0ac5bb";


const userContract721 = "";
const userContract1155 = "";
let toasterOption = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};
if (EnvName === "production") {
} else if (EnvName === "demo") {
  Front_URL = "https://pramidions-nft-frontend.pages.dev/pyramidions";
  Back_URL = "https://pramidionsapi.wealwin.com/";
  v1Url = "https://pramidionsapi.wealwin.com/api";
  IPFS_IMG = "https://ipfs.io/ipfs";
} else {
  Front_URL = "http://localhost:3000";
  Back_URL = "http://localhost:2516/";
  v1Url = "http://localhost:2516/api";
  IPFS_IMG = "https://ipfs.io/ipfs";
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  vUrl: v1Url,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  tokenSymbol: tokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  singleContract: singleContract,
  multipleContract: multipleContract,
  tokenAddress: tokenAddress,
  userContract721: userContract721,
  userContract1155: userContract1155,
  adminAddress: adminAddress,
  singleType: singleType,
  multipleType: multipleType,
  IPFS_IMG: IPFS_IMG,
  providercon: providercon,
  owntokenSymbol: owntokenSymbol,
  esctokenAddr: esctokenAddr,
  fee: fee,
  defaultCollectionSingle: defaultCollectionSingle,
  defaultCollectionMultiple: defaultCollectionMultiple,
};

export default key;
